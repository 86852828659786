import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
  InputAdornment,
  Switch,
  IconButton,
  Stepper,
  Step,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  StepButton,
  Fab,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import LoadPatientInfoDialog from "./LoadPatientInfoDialog";
import { useForm } from "react-hook-form";
import FormatListChecks from "mdi-material-ui/FormatListChecks";
import HelpCircleOutline from "mdi-material-ui/HelpCircleOutline";
import LoadDosingInfoDialog from "./LoadDosingInfoDialog";
import clsx from "clsx";
import { getPatientInfoObject } from "../PatientInfo";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  TextField: {
    margin: theme.spacing(3),
  },
  patDataform: {
    [theme.breakpoints.down("sm")]: {
      padding: 4,
    },
  },
  patDataPane: {
    padding: 20,
    borderRight: "1px solid #949494",
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
      padding: 10,
      width: 320,
    },
  },
  dosingPane: {
    padding: "5px 20px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      width: 320,
    },
  },
  dataAndDosingPane: {
    alignItems: "top",
    justifyContent: "center",
  },
  WtHtDiv: {
    border: "1px solid black",
  },
  unitSelect: {
    transform: "translate(0, 21px) scale(1)",
  },
  protocolDiv: {
    marginTop: 20,
  },
  genderDiv: {
    marginTop: 10,
  },
  intervalInputDiv: {
    padding: 20,
    maxHeight: 500,
    overflowY: "auto",
  },
  intervalCalcButtons: {
    padding: "5px 20px",
  },
  intervalInputTable: {
    margin: "0 auto",
  },
  addBtnDiv: {
    padding: "5px 20px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
  addBtn: {
    float: "right",
    marginRight: 20,
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
    },
  },
  saveBtn: {
    marginLeft: 20,
  },
  actBtnsDiv: {
    justifyContent: "center",
  },
  dialogPaper: {
    width: 900,
    [theme.breakpoints.up("md")]: {
      height: 840,
    },
  },
  albuminQuestionBottomSpacing: {
    marginBottom: "3px",
  },
  peffQuestionBottomSpacing: {
    marginBottom: "3px",
    paddingTop: 10,
  },
  push10pxdown: {
    marginTop: "15px",
  },
  dialogAction: {
    display: "block",
  },
  dialogActionBtn: {
    padding: "6px 20px",
  },
  rightAlign: {
    float: "right",
  },
  selectIdentifierBtn: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  selectIdentifierFab: {
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  identifierPane: {
    textAlign: "center",
  },
  tableInput: {
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  tableInputConc: {
    paddingLeft: 20,
    width: "80%",
  },
  tableInputCreat: {
    width: "80%",
  },

  creatUnitSwitch: {
    display: "block",
    marginTop: "-7px",
  },
  leftSpacing20px: {
    paddingLeft: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingTop: 10,
    },
  },
  firstRowHelperText: {
    color: "red",
    fontStyle: "bold",
  },
  stepper: {
    padding: 0,
  },
  ageTextField: {
    marginTop: 0,
  },
  formControl: {
    "label + &": {
      marginTop: 0,
    },
  },
  downQuestionGrid: {
    paddingTop: 10,
  },
  downQnDropdown: {
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
}));

function getSteps() {
  return ["Patient Data and Dosing", "Measurements"];
}

const PatientInfoAndDosingDialog = ({
  patientDataDialogOpen,
  handlePatientDataDialogClose,
  patientData,
  handlePatientDataInputChange,
  handlePatientDataSubmit,
  handleHtWtUnitToggle,
  handleCreatUnitChange,
  handleCellValueChange,
  handleAddRow,
  handleAddMeasurementsToPatientData,
  handleRemoveRow,
  handlePatientDataLoad,
  loadGraphForPatientData,
  handleClearData,
  handleDownload,
  loadPatientDialogOpen,
  handleLoadPatientDialogOpen,
  handleLoadPatientDialogClose,
  loadMeasurementsDialog,
  handleLoadMeasurementsDialogOpen,
  handleLoadMeasurementsDialogClose,
  handleTimeInHoursChange,
}) => {
  const { register, handleSubmit, errors, clearErrors, trigger } = useForm({
    mode: "onBlur",
  });
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeStep, setActiveStep] = useState(0);
  const [fileLoadErrors, setFileLoadErrors] = useState([]);

  const stepPropToTextField = {
    step: "any",
  };

  const { trackEvent } = useMatomo();

  const handleStep = (step) => async () => {
    if (step === 1) {
      //moving to second tab - validate
      await trigger();

      let valid =
        patientData.identifier &&
        patientData.age &&
        patientData.weight &&
        patientData.height &&
        patientData.dose;

      if (valid) {
        trackEvent({
          category: "Data Entry",
          action: "Move to Measurements Tab",
          name: "Move to Measurements Tab",
        });
        setActiveStep(step);
      }

      return;
    }
    setActiveStep(step);
  };

  const calculateBSA = () => {
    let wt = patientData.weight;
    let ht = patientData.height;
    if (!patientData.metricunits) {
      wt = patientData.weight / 2.205;
      ht = patientData.height * 2.54;
    }
    // let bsa = 0.007184 * Math.pow(ht, 0.725) * Math.pow(wt, 0.425);
    let bsa = Math.sqrt((ht * wt) / 3600);
    return bsa.toFixed(3);
  };

  const handleUploadedFile = (fileData) => {
    //TODO: populate validation errors in this array
    //error if multiple records are present in a file.
    if (fileData.length > 2) {
      setFileLoadErrors([
        "Multiple patient data records found in the uploaded file. Expected one record.",
      ]);
      return;
    }

    //data struct
    const patientData = getPatientInfoObject();
    //parse fileData and throw Errors if any.

    // identify the total parameters (time, conc, creat) to be added
    if (fileData.length > 1) {
      fileData[0].forEach((header, index) => {
        if (patientData.hasOwnProperty(header)) {
          patientData[header] = fileData[1][index];
        } else {
          console.warn("unknown header parsed: ", header, " at ", index);
        }
      });
    }

    handlePatientDataLoad(patientData, "file");

    handleLoadPatientDialogClose();
  };

  const handleSelectionFromLocalStorage = (key) => {
    handleLoadPatientDialogClose();
    let lsPatientData = JSON.parse(
      localStorage.getItem(`mtx-local-data-${key}`)
    );
    handlePatientDataLoad(lsPatientData, "local");
    // clear all the errors
    clearErrors();
  };
  const onSubmit = () => {
    trackEvent({
      category: "Data Entry",
      action: "Save Patient Data",
      name: "Save Patient Data",
    });
    setTimeout(() => {
      // alert(JSON.stringify(patientData, null, 2));
      handlePatientDataSubmit(patientData);
    }, 400);
  };

  const handleImportMeasurements = (
    measurements,
    measTimeInHours,
    defaultCreatUnitInmml
  ) => {
    handleAddMeasurementsToPatientData(
      measurements,
      measTimeInHours,
      defaultCreatUnitInmml
    );
    handleLoadMeasurementsDialogClose();
  };

  const steps = getSteps();

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid container className={classes.dataAndDosingPane}>
              <Grid item sm className={classes.patDataPane}>
                <Typography variant="h6">Patient Data</Typography>
                <Grid container className={classes.identifierPane}>
                  <Grid item xs>
                    <Tooltip title="Identifier is required.">
                      <TextField
                        id="identifier"
                        label="Identifier *"
                        name="identifier"
                        value={patientData.identifier}
                        onChange={handlePatientDataInputChange("identifier")}
                        inputRef={register({ required: true })}
                        style={{ width: 200 }}
                        helperText={
                          Boolean(errors.identifier)
                            ? "Identifier is required"
                            : "Note: Identifier is not saved"
                        }
                        error={Boolean(errors.identifier)}
                        margin="dense"
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    {/* <Button
                    className={classes.selectIdentifierBtn}
                    color="primary"
                    onClick={handleLoadPatientDialogOpen}
                    variant="contained"
                  >
                    Select Patient
                  </Button> */}
                    <Tooltip title="Select Identifier to load">
                      <Fab
                        color="primary"
                        onClick={() => {
                          handleLoadPatientDialogOpen();
                        }}
                      >
                        <FormatListChecks />
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>

                <TextField
                  label="Age *"
                  id="age"
                  name="age"
                  className={classes.ageTextField}
                  style={{ width: 200 }}
                  inputRef={register({ required: true })}
                  helperText={Boolean(errors.age) ? "Age is required" : ""}
                  error={Boolean(errors.age)}
                  value={patientData.age !== 0 ? patientData.age : ""}
                  onChange={handlePatientDataInputChange("age")}
                  margin="dense"
                  type="number"
                  inputProps={{ step: "0.01" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">yrs</InputAdornment>
                    ),
                  }}
                />
                <br />
                <FormControl component="fieldset" className={classes.genderDiv}>
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-label="Gender"
                    name="Gender"
                    value={patientData.sex}
                    onChange={handlePatientDataInputChange("sex")}
                    required={true}
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                <FormControl>
                  <InputLabel id="patient-race-select">Race</InputLabel>
                  <Select
                    labelId="patient-race-select"
                    id="race-select"
                    value={patientData.race}
                    style={{ width: 300, marginTop: 10 }}
                    onChange={handlePatientDataInputChange("race")}
                  >
                    <MenuItem value={"American Indian or Alaska Native"}>
                      American Indian or Alaska Native
                    </MenuItem>
                    <MenuItem value={"Asian"}>Asian</MenuItem>
                    <MenuItem value={"White"}>White</MenuItem>
                    <MenuItem value={"Black or African American"}>
                      Black or African American
                    </MenuItem>
                    <MenuItem value={"Hispanic or Latino"}>
                      Hispanic or Latino
                    </MenuItem>
                    <MenuItem
                      value={"Native Hawaiian or Other Pacific Islander"}
                    >
                      Native Hawaiian or Other Pacific Islander
                    </MenuItem>
                  </Select>
                </FormControl>
                <br />
                <FormControl>
                  <InputLabel id="patient-ethnicity-select">
                    Ethnicity
                  </InputLabel>
                  <Select
                    id="ethnicity-select"
                    value={patientData.ethnicity}
                    style={{ width: 200 }}
                    onChange={handlePatientDataInputChange("ethnicity")}
                  >
                    <MenuItem value={"Hispanic"}>Hispanic</MenuItem>
                    <MenuItem value={"Non Hispanic"}>Non Hispanic</MenuItem>
                  </Select>
                </FormControl>
                <br />

                <Grid container>
                  <Grid item container spacing={1}>
                    <Grid item xs>
                      <TextField
                        id="weight"
                        label="Weight"
                        name="weight"
                        style={{ width: 100 }}
                        value={
                          patientData.weight !== 0.0 ? patientData.weight : ""
                        }
                        onChange={handlePatientDataInputChange("weight")}
                        margin="dense"
                        inputRef={register({ required: true })}
                        helperText={
                          Boolean(errors.weight) ? "weight is required" : ""
                        }
                        error={Boolean(errors.weight)}
                        required={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {patientData.metricunits ? "kg" : "lbs"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        label="Height"
                        id="height"
                        name="height"
                        style={{ width: 100 }}
                        value={
                          patientData.height !== 0 ? patientData.height : ""
                        }
                        onChange={handlePatientDataInputChange("height")}
                        margin="dense"
                        inputRef={register({ required: true })}
                        helperText={
                          Boolean(errors.height) ? "height is required" : ""
                        }
                        error={Boolean(errors.height)}
                        required={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {patientData.metricunits ? "cm" : "in"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="Imperial Unit"
                      control={
                        <Switch
                          checked={patientData.metricunits === false}
                          color="primary"
                          onChange={handleHtWtUnitToggle}
                        />
                      }
                      label="Imperial Unit"
                    />
                  </FormControl>
                </Grid>

                <Typography variant="body1">
                  Surface: {calculateBSA()} m<sup>2</sup>
                </Typography>
                <br />
                <Typography
                  variant="body2"
                  paragraph
                  className={classes.albuminQuestionBottomSpacing}
                >
                  Has the patient had a baseline albumin result less than 2.5
                  g/dL?
                </Typography>

                <FormControl>
                  <Select
                    id="albumin-select"
                    value={patientData.baselinealbumin}
                    style={{ width: 80 }}
                    onChange={handlePatientDataInputChange("baselinealbumin")}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
                <Grid
                  container
                  alignItems="center"
                  className={classes.downQuestionGrid}
                >
                  <Grid item style={{ justifyContent: "center" }}>
                    <Typography
                      variant="body2"
                      paragraph
                      className={classes.albuminQuestionBottomSpacing}
                    >
                      Does the patient have Down Syndrome?
                    </Typography>
                  </Grid>
                  <Grid item className={classes.downQnDropdown}>
                    <FormControl>
                      <Select
                        id="down-syndrome-select"
                        value={patientData.down}
                        style={{ width: 80 }}
                        onChange={handlePatientDataInputChange("down")}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography
                  variant="body2"
                  paragraph
                  className={classes.peffQuestionBottomSpacing}
                >
                  Did the patient have a pleural effusion at the time of
                  infusion or develop one during the elimination of MTX?
                </Typography>

                <FormControl>
                  <Select
                    id="pleural-effusion-select"
                    value={patientData.peff}
                    style={{ width: 80 }}
                    onChange={handlePatientDataInputChange("peff")}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm className={classes.dosingPane}>
                <Typography variant="h6" paragraph>
                  Dosing
                </Typography>

                <FormControl>
                  <InputLabel id="patient-indication-select">
                    Indication *
                  </InputLabel>
                  <Select
                    id="indication"
                    name="indication"
                    value={patientData.indication}
                    style={{ width: 200 }}
                    onChange={handlePatientDataInputChange("indication")}
                  >
                    <MenuItem value={"Leukemia"}>Leukemia</MenuItem>
                    <MenuItem value={"Osteosarcoma"}>Osteosarcoma</MenuItem>
                    <MenuItem value={"Lymphoma"}>Lymphoma</MenuItem>
                  </Select>
                </FormControl>

                <br />
                <Tooltip title="Total dose in grams/m&#178;. Can be split into a loading dose (i.e. 10% in first 0.5 h) and main dose.">
                  <TextField
                    label="Dose *"
                    id="dose"
                    name="dose"
                    inputRef={register({ required: true })}
                    helperText={Boolean(errors.dose) ? "Dose is required" : ""}
                    error={Boolean(errors.dose)}
                    value={patientData.dose !== 0 ? patientData.dose : ""}
                    onChange={handlePatientDataInputChange("dose")}
                    margin="dense"
                    style={{ width: 200 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" disableTypography={true}>
                          gm/m&#178;
                        </InputAdornment>
                      ),
                    }}
                  />
                </Tooltip>
                <br />
                <div className={classes.protocolDiv}>
                  <Grid container direction="column">
                    <Typography variant="body1">Protocol</Typography>
                    <Grid item container>
                      <Grid item sm>
                        <TextField
                          id="load"
                          label="Load"
                          name="load"
                          inputRef={register({ required: true })}
                          helperText={
                            Boolean(errors.load) ? "Load is required" : ""
                          }
                          error={Boolean(errors.load)}
                          required={true}
                          value={patientData.load}
                          onChange={handlePatientDataInputChange("load")}
                          margin="dense"
                          style={{ width: 100 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item sm>
                        <TextField
                          label="In"
                          required={true}
                          id="loadin"
                          name="loadin"
                          inputRef={register({ required: true })}
                          helperText={
                            Boolean(errors.loadin)
                              ? "load time is required"
                              : ""
                          }
                          error={Boolean(errors.loadin)}
                          value={patientData.loadin}
                          onChange={handlePatientDataInputChange("loadin")}
                          margin="dense"
                          style={{ width: 100 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">h</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item sm>
                        <TextField
                          label="Main"
                          id="main"
                          name="main"
                          inputRef={register({ required: true })}
                          helperText={
                            Boolean(errors.main) ? "Main is required" : ""
                          }
                          error={Boolean(errors.main)}
                          required={true}
                          value={patientData.main}
                          onChange={handlePatientDataInputChange("main")}
                          margin="dense"
                          style={{ width: 100 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item sm>
                        <TextField
                          label="In"
                          id="mainin"
                          name="mainin"
                          inputRef={register({ required: true })}
                          helperText={
                            Boolean(errors.mainin)
                              ? "Main time is required"
                              : ""
                          }
                          error={Boolean(errors.mainin)}
                          required={true}
                          value={patientData.mainin}
                          onChange={handlePatientDataInputChange("mainin")}
                          margin="dense"
                          style={{ width: 100 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">h</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <Tooltip title="Number of hours after the start of infusion to simulate">
                  <TextField
                    label="Simulation "
                    id="sim"
                    name="sim"
                    // inputRef={register({ required: true })}
                    //  helperText={
                    //     "Simulation time is required"
                    //  }
                    // error={Boolean(errors.sim)}
                    value={patientData.sim !== 0 ? patientData.sim : ""}
                    onChange={handlePatientDataInputChange("sim")}
                    margin="dense"
                    style={{ width: 200 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">h</InputAdornment>
                      ),
                    }}
                  />
                </Tooltip>
                <div className={classes.protocolDiv}>
                  <Grid container direction="column">
                    <Typography variant="body1">
                      Elimination Threshold
                    </Typography>
                    <Grid item container>
                      <Grid item sm>
                        <Tooltip title="Upper limit for elimination threshold">
                          <TextField
                            label="Upper"
                            id="upperconc"
                            name="upperconc"
                            inputRef={register({ required: false })}
                            value={patientData.upperconc}
                            onChange={handlePatientDataInputChange("upperconc")}
                            margin="dense"
                            style={{ width: 100 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  &#956;M
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item sm>
                        <Tooltip title="Lower limit for elimination threshold">
                          <TextField
                            label="Lower"
                            id="lowerconc"
                            name="lowerconc"
                            inputRef={register({ required: false })}
                            value={patientData.lowerconc}
                            onChange={handlePatientDataInputChange("lowerconc")}
                            margin="dense"
                            style={{ width: 100 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  &#956;M
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <div className={classes.addBtnDiv}>
              <Tooltip
                title="Download the current patient info and dosing info as a csv file"
                aria-label="download as csv file"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownload("patientInfo")}
                >
                  Download patient info
                </Button>
              </Tooltip>
              <span className={classes.leftSpacing20px}>
                <Tooltip title="Save to local" aria-label="Save to local">
                  <Button variant="contained" type="submit" color="primary">
                    Save
                  </Button>
                </Tooltip>
              </span>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className={classes.intervalInputDiv}>
              <table className={classes.intervalInputTable}>
                <thead>
                  <tr>
                    <th>
                      {patientData.timeInHours ? "Time [h]" : "Date and Time"}
                      {patientData.timeInHours ? (
                        <Tooltip title="Enter time since the start of infusion in hours when MTX concentration and/or creatinine was measured">
                          <IconButton aria-label="Enter time since the start of infusion in hours when MTX concentration and/or creatinine was measured">
                            <HelpCircleOutline />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Enter date and time of infusion when MTX concentration and/or creatinine was measured">
                          <IconButton aria-label="Enter date and time of infusion when MTX concentration and/or creatinine was measured">
                            <HelpCircleOutline />
                          </IconButton>
                        </Tooltip>
                      )}
                      <FormControlLabel
                        className={classes.creatUnitSwitch}
                        control={
                          <Switch
                            checked={patientData.timeInHours === true}
                            color="primary"
                            onChange={handleTimeInHoursChange}
                          />
                        }
                        label={
                          patientData.timeInHours === true
                            ? "Hours"
                            : "Date and Time"
                        }
                      />
                    </th>
                    <th>
                      Conc [&#956;M]
                      <Tooltip title="Enter the MTX concentration in uM at the specified time on the same row">
                        <IconButton aria-label="Enter the MTX concentration in uM at the specified time on the same row">
                          <HelpCircleOutline />
                        </IconButton>
                      </Tooltip>
                    </th>
                    <th>
                      {patientData.creatunitmml
                        ? "Creatinine [μmol/L]"
                        : "Creatinine [mg/dL]"}
                      <Tooltip
                        title={
                          patientData.creatunitmml
                            ? "Enter the Creatinine concentration in μmol/L at the specified time on the same row"
                            : "Enter the Creatinine concentration in mg/dL at the specified time on the same row"
                        }
                      >
                        <IconButton aria-label="creatinine concentration">
                          <HelpCircleOutline />
                        </IconButton>
                      </Tooltip>
                      <FormControlLabel
                        className={classes.creatUnitSwitch}
                        control={
                          <Switch
                            checked={patientData.creatunitmml === true}
                            color="primary"
                            onChange={handleCreatUnitChange}
                          />
                        }
                        label={
                          patientData.creatunitmml === true
                            ? "values in μmol/L Unit"
                            : "values in mg/dL Unit"
                        }
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patientData.parameters.map((row, index) => (
                    <tr key={index}>
                      <td>
                        {patientData.timeInHours ? (
                          <TextField
                            name="time"
                            type="number"
                            size="small"
                            className={classes.tableInput}
                            value={patientData.parameters[index].time}
                            onChange={handleCellValueChange(index)}
                            inputProps={stepPropToTextField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        ) : (
                          <TextField
                            name="time"
                            type="datetime-local"
                            size="small"
                            className={classes.tableInput}
                            value={patientData.parameters[index].time}
                            onChange={handleCellValueChange(index)}
                            inputProps={stepPropToTextField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            FormHelperTextProps={{
                              className: classes.firstRowHelperText,
                            }}
                            helperText={
                              index === 0 && "*Dosing induction date and time"
                            }
                          />
                        )}
                      </td>
                      <td>
                        <TextField
                          type="number"
                          step="any"
                          name="conc"
                          size="small"
                          className={clsx(
                            classes.tableInput,
                            classes.tableInputConc
                          )}
                          value={patientData.parameters[index].conc}
                          onChange={handleCellValueChange(index)}
                          helperText={
                            patientData.parameters[index].time !== "" &&
                            patientData.parameters[index].conc === ""
                              ? "a numerical value is required"
                              : ""
                          }
                          error={
                            patientData.parameters[index].time !== "" &&
                            patientData.parameters[index].conc === ""
                          }
                          inputProps={stepPropToTextField}
                        />
                      </td>
                      <td>
                        <TextField
                          type="number"
                          name="creat"
                          size="small"
                          className={clsx(
                            classes.tableInput,
                            classes.tableInputCreat
                          )}
                          value={patientData.parameters[index].creat}
                          onChange={handleCellValueChange(index)}
                          helperText={
                            patientData.parameters[index].time !== "" &&
                            patientData.parameters[index].creat === ""
                              ? "a numerical value is required"
                              : ""
                          }
                          error={
                            patientData.parameters[index].time !== "" &&
                            patientData.parameters[index].creat === ""
                          }
                          inputProps={stepPropToTextField}
                        />
                      </td>
                      <td>
                        <IconButton
                          className={classes.delBtn}
                          aria-label="delete"
                          onClick={handleRemoveRow(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={classes.addBtnDiv}>
              <Tooltip
                title="Download the measurements as a csv file"
                aria-label="download measurements as csv file"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownload("measurements")}
                >
                  Download Measurements
                </Button>
              </Tooltip>
              <span className={classes.leftSpacing20px}>
                <Tooltip title="Save to local" aria-label="Save to local">
                  <Button variant="contained" type="submit" color="primary">
                    Save
                  </Button>
                </Tooltip>
              </span>
              <Button
                className={classes.addBtn}
                onClick={handleAddRow}
                variant="contained"
                size="large"
                color="primary"
              >
                Add Row
              </Button>
              <Button
                className={classes.addBtn}
                onClick={handleLoadMeasurementsDialogOpen}
                variant="contained"
                size="large"
                color="primary"
              >
                Import measurements
              </Button>
            </div>
          </>
        );
      default:
        return;
    }
  }

  return (
    <Dialog
      open={patientDataDialogOpen}
      onClose={handlePatientDataDialogClose}
      aria-labelledby="patient data and dosing"
      fullScreen={fullScreen}
      maxWidth={false}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.patDataform}>
          <Stepper
            nonLinear
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepper}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton onClick={handleStep(index)}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          {getStepContent(activeStep)}
          {/* <Tooltip
            title="Download the current patient info and dosing info as a csv file"
            aria-label="download as csv file"
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.saveBtn}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Tooltip> */}
        </form>
        <LoadPatientInfoDialog
          loadPatientDialogOpen={loadPatientDialogOpen}
          fileLoadErrors={fileLoadErrors}
          setFileLoadErrors={setFileLoadErrors}
          handleLoadPatientDialogClose={handleLoadPatientDialogClose}
          handleSelectionFromLocalStorage={handleSelectionFromLocalStorage}
          handleUploadedFile={handleUploadedFile}
        />
        <LoadDosingInfoDialog
          loadMeasurementsDialog={loadMeasurementsDialog}
          fileLoadErrors={fileLoadErrors}
          setFileLoadErrors={setFileLoadErrors}
          handleLoadMeasurementsDialogClose={handleLoadMeasurementsDialogClose}
          handleImportMeasurements={handleImportMeasurements}
        />
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button color="primary" onClick={handlePatientDataDialogClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleClearData}>
          Clear
        </Button>

        <div className={classes.rightAlign}>
          <span className={classes.leftSpacing20px}>
            {activeStep === 0 && (
              <Button
                color="primary"
                variant="contained"
                onClick={handleStep(1)}
              >
                Next
              </Button>
            )}
            {activeStep === 1 && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  trackEvent({
                    category: "User Interaction",
                    action: "Calculate",
                    name: "Calculate",
                  });
                  loadGraphForPatientData();
                }}
              >
                Calculate
              </Button>
            )}
          </span>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PatientInfoAndDosingDialog;
