import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import mailImg from "../../images/contactMail.svg";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  contactContainer: {
    minHeight: "800px",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      minHeight: "100%",
      overflow: "hidden",
    },
  },
  form: {
    position: "relative",
    paddingTop: 30,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      paddingTop: 10,
    },
  },
  textField: {
    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
  },
  submitButton: {
    float: "right",
    marginTop: 30,
    backgroundColor: "#4d82cb",
  },
  mailImg: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    position: "absolute",
    top: -37,
    right: -91,
    width: "40%",
  },
  headerText: {
    color: "#4d82cb",
    fontWeight: 500,
  },
  boldText: {
    fontWeight: 500,
  },
}));

const Contact = () => {
  const classes = useStyles();

  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: "Contact Us",
      href: "/contact",
    });
  }, [trackPageView]);

  const [contactFormValues, setContactFormValues] = useState({
    name: "",
    email: "",
    role: "",
    institution: "",
  });

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setContactFormValues({
        ...contactFormValues,
        [name]: value,
      });
    };

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  const openEmailClientsWithDetails = () => {
    let mail = document.createElement("a");
    mail.href = `mailto:mtxpk@cchmc.org?subject=I would like to know more about MTXPK.org!&body=Please find my details below: %0D%0A Name: ${contactFormValues.name} %0D%0A EMAIL: ${contactFormValues.email} %0D%0A ROLE: ${contactFormValues.role} %0D%0A INSTITUTION: ${contactFormValues.institution}`;
    mail.click();
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.contactContainer}
    >
      <Grid item>
        <form
          autoComplete="off"
          onSubmit={handleFormSubmit}
          className={classes.form}
        >
          <img className={classes.mailImg} src={mailImg} alt="mail icon" />
          <Typography variant="h4" paragraph className={classes.headerText}>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph className={classes.boldText}>
            mtxpk@cchmc.org
          </Typography>

          <TextField
            label="NAME"
            className={classes.textField}
            value={contactFormValues.name}
            onChange={handleChange("name")}
            margin="normal"
          />
          <br />
          <TextField
            label="EMAIL"
            className={classes.textField}
            value={contactFormValues.email}
            onChange={handleChange("email")}
            margin="normal"
          />
          <br />
          <TextField
            label="ROLE"
            className={classes.textField}
            value={contactFormValues.role}
            onChange={handleChange("role")}
            margin="normal"
          />
          <br />
          <TextField
            label="INSTITUTION"
            className={classes.textField}
            value={contactFormValues.institution}
            onChange={handleChange("institution")}
            margin="normal"
          />
          <br />
          <Button
            className={classes.submitButton}
            variant="contained"
            size="medium"
            color="primary"
            onClick={openEmailClientsWithDetails}
          >
            Send Email
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default Contact;
