import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import { Button } from "@material-ui/core";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const useStyles = makeStyles((theme) => ({
  zone: {
    alignItems: "center",
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
}));

const LoadPatientRecordsFromCSV = ({
  infoText,
  setFileData,
  setFileLoadErrors,
}) => {
  const classes = useStyles();
  const { trackEvent } = useMatomo();

  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  return (
    <CSVReader
      config={{
        skipEmptyLines: true,
      }}
      onUploadAccepted={({ data, errors, meta }) => {
        trackEvent({
          category: "Data Entry",
          action: "Upload patient data from file",
          name: "Upload patient data from file",
        });
        setZoneHover(false);
        setFileLoadErrors([]);
        setFileData(data);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setZoneHover(false);
      }}
      noDrag
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove,
      }) => (
        <div
          {...getRootProps()}
          className={Object.assign(
            {},
            classes.zone,
            zoneHover && classes.zoneHover
          )}
        >
          {acceptedFile ? (
            <>
              <div className={classes.file}>
                <div className={classes.info}>
                  <span className={classes.size}>
                    {formatFileSize(acceptedFile.size)}
                  </span>
                  <span className={classes.name}>{acceptedFile.name}</span>
                </div>
                <div className={classes.progressBar}>
                  <ProgressBar />
                </div>
                <div
                  {...getRemoveFileProps()}
                  className={classes.remove}
                  onMouseOver={(event) => {
                    event.preventDefault();
                    setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                  }}
                  onMouseOut={(event) => {
                    event.preventDefault();
                    setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                  }}
                >
                  <Remove color={removeHoverColor} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Button
                color="primary"
                className={classes.dialogActionsRight}
                variant="contained"
              >
                {infoText}
              </Button>
            </>
          )}
        </div>
      )}
    </CSVReader>
  );
};

export default LoadPatientRecordsFromCSV;
