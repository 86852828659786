import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  useTheme,
  DialogContent,
  Button,
  useMediaQuery,
  DialogTitle,
  TextareaAutosize,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Typography,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { makeStyles } from "@material-ui/styles";
import LoadPatientRecordsFromCSV from "../CSVParser";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: 700,
    height: 400,
  },
  helpText: {
    paddingBottom: 10,
  },
  dialogActions: {
    display: "block",
  },
  dialogActionsRight: {
    float: "right",
  },
  dialogActionsLeft: {
    float: "left",
  },
}));

const LoadDosingInfoDialog = ({
  loadMeasurementsDialog,
  handleLoadMeasurementsDialogClose,
  fileLoadErrors,
  setFileLoadErrors,
  handleImportMeasurements,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useMatomo();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [textAreavalue, setTextAreavalue] = useState("");

  //text or file
  const [dataSource, setDataSource] = useState("");
  const [fileData, setFileData] = useState([]);

  const handleMeasurementsData = (e) => {
    setDataSource("text");
    setTextAreavalue(e.target.value);
  };

  useEffect(() => {
    if (fileData.length > 0) {
      setDataSource("file");
    } else {
      setDataSource("");
    }
  }, [fileData]);

  useEffect(() => {
    if (textAreavalue !== "") {
      setDataSource("text");
    } else {
      setDataSource("");
    }
  }, [textAreavalue]);

  const formatDateTime = (datePart, time) => {
    const year = datePart.getFullYear();
    const month =
      datePart.getMonth() + 1 < 10
        ? "0" + (datePart.getMonth() + 1)
        : datePart.getMonth() + 1;
    const date =
      datePart.getDate() < 10 ? "0" + datePart.getDate() : datePart.getDate();

    //format time - prepend '0'
    const formattedTime = ("0" + time).slice(-5);

    return year + "-" + month + "-" + date + "T" + formattedTime;
  };

  const handleParseData = () => {
    //check the source (text or file)
    if (dataSource === "text") {
      let measData = [];
      let measTimeInHours = true;
      try {
        const rows = textAreavalue.split("\n");
        const re = /[ ,\t]/gm;
        rows.forEach((row) => {
          if (row.trim()) {
            let rowData = row.split(re);
            // to handle a usecase where ', ' is present
            rowData = rowData.map((rd) => rd.trim()).filter((rd) => rd);

            // we have date and time
            let parsedRow = {};
            if (rowData.length === 4) {
              measTimeInHours = false;
              const datePart = new Date(rowData[0]);
              const timePart = rowData[1];
              const formattedDateTime = formatDateTime(datePart, timePart);
              parsedRow = {
                time: formattedDateTime,
                conc: rowData[2],
                creat: rowData[3],
              };
            } else {
              measTimeInHours = true;

              //error on when date only present
              if (rowData[0].includes("/")) {
                throw new Error(
                  "Date and Time is required when entering date and time."
                );
              }

              parsedRow = {
                time: rowData[0],
                conc: rowData[1],
                creat: rowData[2],
              };
            }
            measData.push(parsedRow);
          }
        });
      } catch (error) {
        //TODO notify on screen??
        console.error("Errror parsing the measurements data", error);
      }
      const defaultCreatUnitInmml = measData.every((ob) => ob["creat"] >= 10);
      handleImportMeasurements(
        measData,
        measTimeInHours,
        defaultCreatUnitInmml
      );
    } else if (dataSource === "file") {
      let measData = [];
      let measTimeInHours = true;
      try {
        for (let i = 1; i < fileData.length; i++) {
          if (fileData[i].length > 0) {
            // mm/dd/yyyy HH:MM IS THE TIME
            if (fileData[i][0].includes("-") || fileData[i][0].includes("/")) {
              measTimeInHours = false;
            }

            let parsedRow = {};
            if (measTimeInHours) {
              parsedRow = {
                time: fileData[i][0],
                conc: fileData[i][1],
                creat: fileData[i][2],
              };
            } else {
              const parsedDateTime = new Date(fileData[i][0]);
              parsedRow = {
                time: formatDateTime(
                  parsedDateTime,
                  parsedDateTime.getHours() + ":" + parsedDateTime.getMinutes()
                ),
                conc: fileData[i][1],
                creat: fileData[i][2],
              };
            }

            measData.push(parsedRow);
          }
        }
      } catch (error) {
        console.error("Errror parsing the measurements data", error);
      }

      //if all the creat values are > 10, then the unit is ummol/L
      const defaultCreatUnitInmml = measData.every((ob) => ob["creat"] >= 10);

      handleImportMeasurements(
        measData,
        measTimeInHours,
        defaultCreatUnitInmml
      );
    }
  };
  return (
    <Dialog
      open={loadMeasurementsDialog}
      onClose={handleLoadMeasurementsDialogClose}
      aria-labelledby="Load Patient Data"
      fullScreen={fullScreen}
      maxWidth={false}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="load-patient-data-dialog-title">
        Import Measurements
      </DialogTitle>
      <DialogContent>
        <Container>
          <Typography variant="subtitle2">
            Enter or paste the measurements
          </Typography>
          <Typography variant="caption">
            Accepted date and time formats: <b>MM/DD/YYYY HH:MM</b> or{" "}
            <b>MM/DD/YYYY,HH:MM</b>
          </Typography>
          <Typography variant="caption" display="block">
            E.g., <br />
            03/10/2022 13:24 95 0.52 (for measurements with date and time),
            <br />
            24 0.95 0.32 (for measurements with hour)
          </Typography>
        </Container>
        <List>
          <ListItem className={classes.listItem}>
            <TextareaAutosize
              value={textAreavalue}
              onChange={handleMeasurementsData}
              aria-label="text area"
              minRows={10}
              placeholder="paste measurements here"
              style={{ width: 700, height: 500 }}
            />
          </ListItem>
        </List>

        <List
          subheader={
            <ListSubheader component="div" id="load-patient-data">
              Import from file (.csv)
            </ListSubheader>
          }
        >
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <AttachFileIcon />
            </ListItemIcon>
            <ListItemText>
              <LoadPatientRecordsFromCSV
                infoText="Click to upload measurements data"
                setFileData={setFileData}
                setFileLoadErrors={setFileLoadErrors}
                setDataSource={setDataSource}
              />
            </ListItemText>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="primary"
          onClick={() => {
            trackEvent({
              category: "Data Entry",
              action: "Parse Measurements",
              name: "Parse Measurements",
            });
            handleParseData();
          }}
          className={classes.dialogActionsRight}
          variant="contained"
          disabled={dataSource === ""}
        >
          Parse{" "}
          {dataSource === "text" ? "text" : dataSource === "file" ? "file" : ""}
        </Button>
        <Button
          color="primary"
          onClick={handleLoadMeasurementsDialogClose}
          className={classes.dialogActionsLeft}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadDosingInfoDialog;
