import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import bannerImg from "../../images/bannerImg.svg";
import clsx from "clsx";
import { Link } from "react-router-dom";
import InstallPopup from "../../Components/IOSPopup";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  banner: {
    display: "flex",
    backgroundColor: "#4c82cb",
    alignItems: "center",
  },

  bannerText: {
    color: "white",
    fontSize: "x-large",
    fontWeight: 500,
    flex: 1,
    padding: 20,
  },

  bannerImgDiv: {
    flex: 2,
    position: "relative",
    height: "100%",
    "& img": {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },

  removeBannerImgForSmlScreens: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  bannerImg: {
    position: "absolute",
    bottom: 0,
    margin: "0 auto",
    height: "100%",
  },

  homeIntroTextPane: {
    paddingTop: 65,
    width: "80%",
    margin: "0 auto",

    [theme.breakpoints.down("lg")]: {
      paddingTop: 25,
      width: "90%",
    },
  },

  textPane: {
    paddingTop: 20,
    paddingBottom: 20,
  },

  headerText: {
    color: "#4D82CB",
  },
  boldText: {
    fontWeight: 500,
  },
  introBiggerText: {
    fontSize: "x-large",
  },
  bgColor: {
    backgroundColor: "#f6f6f6",
    zIndex: -1,
  },
  getStartedBtnDiv: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  getStartedBtn: {
    margin: "0 auto",
  },
  container: {
    height: "100%",
  },
  aboutNoticeText: {
    margin: "0 auto",
    padding: "0 20px",
  },
  homeIntroPane: {
    alignItems: "center",
  },
  page: {
    [theme.breakpoints.down("md")]: {
      paddingBottom: "50px",
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const { trackPageView } = useMatomo();
  const [showInstallMessage, setShowInstallMessage] = useState(false);

  useEffect(() => {
    trackPageView({
      documentTitle: "Home",
      href: "/",
    });
  }, [trackPageView]);

  useEffect(() => {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      if (
        localStorage.length &&
        localStorage.getItem("mtxpk_installmsg_flag")
      ) {
        const savedInstallMsg = JSON.parse(
          localStorage.getItem("mtxpk_installmsg_flag")
        );

        const savedInstallMsgTime = new Date(parseInt(savedInstallMsg.date));

        // show dialog only if the setTime is prior to 24 hours from today.
        if (new Date().getTime() - savedInstallMsgTime > 86400000) {
          setShowInstallMessage(true);
        }
      } else {
        setShowInstallMessage(true);
      }
    }
    //preload sample data
    if (!localStorage.getItem("mtx-local-data-Sample-data")) {
      const sampleData = {
        identifier: "Sample-data",
        sex: "female",
        weight: "30",
        height: "130",
        metricunits: true,
        creatunitmml: false,
        age: "9",
        race: "American Indian or Alaska Native",
        ethnicity: "Non Hispanic",
        surface: 0,
        indication: "Leukemia",
        dose: 5,
        sim: 0,
        load: 10,
        loadin: 0.5,
        main: 90,
        mainin: 23.5,
        predictioninterval: 95,
        baselinealbumin: false,
        timeInHours: false,
        down: false,
        peff: false,
        parameters: [
          { time: "2022-08-23T13:19", conc: "0", creat: "0" },
          { time: "2022-08-24T13:19", conc: "95", creat: "0.52" },
          { time: "2022-08-25T13:19", conc: "0.95", creat: "0.32" },
          { time: "2022-08-26T13:19", conc: "0.43", creat: "0.33" },
          { time: "2022-08-27T13:20", conc: "0.15", creat: "0.33" },
          { time: "2022-08-28T13:20", conc: "0.12", creat: "0.33" },
          { time: "2022-08-29T13:20", conc: "0.08", creat: "0.34" },
        ],
        upperconc: 0,
        upperat: 0,
        lowerconc: 0,
        lowerat: 0,
        dateSaved: new Date()
          .toJSON()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("/"),
      };
      localStorage.setItem(
        "mtx-local-data-Sample-data",
        JSON.stringify(sampleData)
      );
    }
  }, []);

  // store a flag in localstorage
  const handleInstallMessageClose = () => {
    if (localStorage.length && localStorage.getItem("mtxpk_installmsg_flag")) {
      localStorage.removeItem("mtxpk_installmsg_flag");
    }

    const installMsg = {
      date: new Date().getTime(),
      dontShowInstallMsg: true,
    };

    localStorage.setItem("mtxpk_installmsg_flag", JSON.stringify(installMsg));

    setShowInstallMessage(false);
  };

  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.banner}>
        <Grid item sm>
          <p className={classes.bannerText}>
            MTXPK.org is designed to help clinicians understand the
            pharmacokinetics of high-dose methotrexate, especially with regard
            to delayed clearance.
          </p>
        </Grid>
        <Grid
          item
          sm
          className={clsx(classes.banner, classes.removeBannerImgForSmlScreens)}
        >
          <div className={classes.bannerImgDiv}>
            <img alt="" src={bannerImg} className={classes.bannerImg} />
          </div>
        </Grid>
      </Grid>
      <div className={classes.page}>
        <Grid item container className={classes.homeIntroPane}>
          <Grid item sm>
            <div className={classes.homeIntroTextPane}>
              <Typography
                variant="h6"
                paragraph
                className={clsx(classes.headerText, classes.boldText)}
              >
                How It Works
              </Typography>
              <Typography variant="body1" paragraph>
                It uses an appropriate pharmacokinetic model for the dose of
                methotrexate to display the concentration vs time curve for an
                individual patient overlaid upon the population-predicted curve
                for that dose.
              </Typography>
              <div className={classes.getStartedBtnDiv}>
                <Link
                  to="/simulation"
                  color="inherit"
                  className={classes.navLink}
                >
                  <Button
                    className={classes.getStartedBtn}
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Get Started
                  </Button>
                </Link>

                {/* <Button
                className={classes.getStartedBtn}
                variant="contained"
                size="large"
                color="primary"
              >
                Get Started
              </Button> */}
              </div>
            </div>
          </Grid>
          <Grid item sm className={classes.bgColor}>
            <div className={classes.homeIntroTextPane}>
              <Typography variant="body1" paragraph>
                Required values include the age, sex, height, and weight of the
                patient, the dose and infusion time, at least one methotrexate
                plasma concentration measurement and one creatinine measurement.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <div className={classes.aboutNoticeText}>
            <Typography variant="h6" paragraph className={classes.headerText}>
              Notice and Disclaimer
            </Typography>
            <Typography variant="body1" paragraph>
              The User acknowledges and agrees that this tool will be used only
              as a reference aid, and that the information contained in the
              product is not intended to be (nor should it be used as) a
              substitute for the exercise of professional judgement.
            </Typography>
            <Typography variant="body1" paragraph>
              In view of the possibility of human error or changes in medical
              science, the User should confirm the information in the product
              through independent sources.
            </Typography>
            <Typography variant="body1" paragraph>
              This tool was developed by the Division of Clinical Pharmacology,
              Cincinnati Children’s Hospital Medical Center (CCHMC), Cincinnati,
              OH 45229 and Medimatics (Maastricht, Netherlands). All rights in
              the tool are reserved by Division of Clinical Pharmacology,
              Cincinnati Children’s Hospital Medical Center, Cincinnati, OH
              45229. We do not make any warranty, express, or imply, or assume
              any liability for the use of this tool. Use of this website is
              taken as an agreement to these terms of usage. MTXPK.org is free
              for academic use.
            </Typography>
          </div>
        </Grid>
      </div>
      <InstallPopup
        showInstallMessage={showInstallMessage}
        handleInstallMessageClose={handleInstallMessageClose}
      />
    </Grid>
  );
};
export default Home;
