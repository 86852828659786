import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import addHighChartsMore from "highcharts/highcharts-more";
import { exportChartsToPdf } from "./utils";
import {
  makeStyles,
  Button,
  Tooltip,
  Paper,
  Typography,
  Divider,
  Grid,
  useMediaQuery,
  useTheme,
  TextField,
} from "@material-ui/core";
import FileChartOutline from "mdi-material-ui/FileChartOutline";
import { getChartOptions } from "./ChartOptions";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

addHighChartsMore(Highcharts);

// add sticky annotation when a point is clicked
Highcharts.addEvent(Highcharts.Point, "click", function () {
  if (this.series.options.className.indexOf("popup-on-click") !== -1) {
    const chart = this.series.chart;
    const series = this.series.chart.options.series;
    const values = series
      .map((ser) => {
        if (ser.data[this.index]) {
          return {
            seriesName: ser.name,
            yValue: ser.data[this.index][1].toFixed(2),
          };
        }
        return null;
      })
      .filter((serObg) => serObg);

    const yText = values
      .map((serObj) => serObj.seriesName + ": " + serObj.yValue + "<br />")
      .toString()
      .replaceAll(",", "");

    const text = `<b>${this.x.toFixed(2)}h</b><br/>${yText}`;

    const id = "sticky_" + this.plotX + "_" + this.plotY;

    chart.addAnnotation({
      id: id,
      plotX: this.plotX,
      plotY: this.plotY,
      labels: [
        {
          point: {
            x: this.plotX,
            y: this.plotY,
          },
          text: text,
        },
      ],
      events: {
        click: () => {
          //memoize id to identify the clicked annotation
          chart.removeAnnotation(id);
        },
      },
    });
  }
});

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/annotations")(Highcharts);

const useStyle = makeStyles((theme) => ({
  pane: {
    padding: 20,
  },
  rightPane: {
    flex: 2,
  },
  midPane: {
    flex: 10,
  },
  reportBtn: {
    paddingTop: 20,
    paddingRight: 50,
    paddingBottom: 100,
    float: "right",
  },
  reportActionBtn: {
    marginTop: "40px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "inline",
    },
  },

  criticalWindowPanePaper: {
    height: "800px",
    padding: "0 10px",
    float: "right",
    marginLeft: "10px",

    [theme.breakpoints.down("md")]: {
      float: "none",
      marginLeft: "0",
      marginBotton: "10px",
      height: "100%",
    },
  },
  criticanWindowDiv: {
    padding: 20,
  },
  chartDiv: {
    // maxWidth: 1200
  },
  highlightButton: {
    paddingTop: 12,
  },
}));

const Chart = ({
  patientData,
  creatData,
  popHi,
  popMean,
  popRange,
  patientConcData,
  simConcData,
  criticalWindowData,
}) => {
  const classes = useStyle();
  const chart = useRef();
  const { trackEvent } = useMatomo();

  const getCreatinineUnit = () => {
    return patientData.creatunitmml ? " μmol/L" : "mg/dL";
  };

  const ChartOptions = getChartOptions(
    patientData,
    patientConcData,
    creatData,
    simConcData,
    popHi,
    popMean,
    popRange,
    getCreatinineUnit
  );

  const downloadChart = () => {
    exportChartsToPdf(criticalWindowData, patientData);
  };

  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up("lg"));

  const [highlightTime, setHighlightTime] = useState();
  // const [creatinine, setCreatinine] = useState();

  const handleHighlight =
    (name) =>
    ({ target: { value } }) => {
      if (name === "time") {
        setHighlightTime(value);
      } else if (name === "creatinine") {
        // setCreatinine(value);
      }
    };

  const highlightInChart = () => {
    const chartRef = chart.current.chart;
    // series with points defined
    const series = chartRef.series;
    let indexAtTime = 0;
    let pointAtIndex = {};
    // look for data in population mean
    series.forEach((ser) => {
      if (ser.name === "Population mean") {
        indexAtTime = ser.data.findIndex((val) => {
          if (parseFloat(val.x.toFixed(2)) >= highlightTime) {
            return val.x;
          }
          return null;
        });
        pointAtIndex = ser.data[indexAtTime];
      }
    });

    // fire the click event. We have an click event listener on the point already
    pointAtIndex.firePointEvent("click");
  };

  // a placeholder attached to the chart instance for the sticky tooltips
  useEffect(() => {
    trackEvent({
      category: "Chart Load",
      action: "Chart Loaded",
      name: "Chart Loaded",
    });
    chart.current.chart.stickyTooltips = [];
  }, [trackEvent]);

  return (
    <Grid container spacing={0} direction={large ? "row" : "column-reverse"}>
      <Grid item xs={12} lg={9}>
        <div className="mtx-report-chart">
          <HighchartsReact
            highcharts={Highcharts}
            options={ChartOptions}
            height="600"
            ref={chart}
          />
        </div>
        <Grid container spacing={0} direction={"row"}>
          <Grid item>
            <div className="highlightChart">
              <Tooltip title="Highlight time in chart.">
                <TextField
                  label="Highlight Time"
                  name="time"
                  value={highlightTime}
                  onChange={handleHighlight("time")}
                  style={{ width: 200 }}
                  margin="dense"
                />
              </Tooltip>
            </div>
          </Grid>
          <Grid item>
            {/* <div className="highlightChart">
              <Tooltip title="Highlight creatinine level in chart">
                <TextField
                  hidden
                  label="Creatinine Level:"
                  name="creatinine"
                  value={creatinine}
                  onChange={handleHighlight("creatinine")}
                  style={{ width: 200 }}
                  margin="dense"
                />
              </Tooltip>
            </div> */}
          </Grid>
          <Grid item>
            <div className={classes.highlightButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  trackEvent({
                    category: "User Interaction",
                    action: "Highlight Time In Chart",
                    name: "Highlight Time",
                  });
                  highlightInChart();
                }}
              >
                <Typography>Highlight</Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Paper className={classes.criticalWindowPanePaper}>
          <div className={classes.criticanWindowDiv}>
            <Typography variant="h6">Elimination Threshold</Typography>
            <Typography variant="body1">
              <strong>{criticalWindowData.upper.conc}</strong> μM At{" "}
              <strong>{criticalWindowData.upper.time.toFixed(1)}</strong> h
            </Typography>
            <Typography variant="body1">
              <strong>{criticalWindowData.lower.conc}</strong> μM At{" "}
              <strong>{criticalWindowData.lower.time.toFixed(1)}</strong> h
            </Typography>
            <Typography variant="body1">
              AUC: <strong>{criticalWindowData.auc.toFixed(1)}</strong> μM*h
            </Typography>
            <Divider variant="middle" className={classes.divider} />
            <Tooltip title="Download Report">
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  trackEvent({
                    category: "User Interaction",
                    action: "Download Report",
                    name: "Download Report",
                  });
                  downloadChart();
                }}
                className={classes.reportActionBtn}
                startIcon={<FileChartOutline />}
              >
                Download Report
              </Button>
            </Tooltip>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Chart;
